import React from "react";
import classes from "./ViewSelector.module.css";
// MUI
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const ViewSelector = ({ width, view, setView }) => {
  const viewSelector =
    width < 768 ? (
      <div className={classes.ViewSelector}>
        <FormControl className={classes.formControl}>
          {/* <InputLabel id="view-select-label">Vue</InputLabel> */}
          <Select
            labelId="view-select-label"
            id="view-select"
            value={view}
            onChange={(e) => setView(e.target.value)}
          >
            <MenuItem value={1}>Le projet</MenuItem>
            {/* <MenuItem value={2}>Le calendrier</MenuItem> */}
          </Select>
        </FormControl>
      </div>
    ) : (
      <div className={classes.ViewSelector}>
        <p
          onClick={() => setView(1)}
          className={view === 1 ? classes.SelectedView : classes.UnselectedView}
        >
          Le projet
        </p>
        {/* <p
          onClick={() => setView(2)}
          className={view === 2 ? classes.SelectedView : classes.UnselectedView}
        >
          Le calendrier
        </p> */}
      </div>
    );

  return <>{viewSelector}</>;
};

export default ViewSelector;
