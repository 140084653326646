import React, { useState, useEffect } from "react";
import classes from "./ModalUserReclamation.module.css";
// Firebase
import { AuthContext } from "../../../contexts/AuthContext";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Components
import AdminReclamationContent from "./ReclamationContent/AdminReclamationContent";
// Utils
import {
  submitAdminAnswer,
  deleteReclamation,
} from "../../../utils/TravauxUtils";
// utils
import { getLot } from "../../../utils/loc";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ModalAdminReclamation = ({
  show,
  setShow,
  reclamation,
  setReclamations,
  currentUserUid,
}) => {
  const classesMUI = useStyles();
  const [adminAnswer, setAdminAnswer] = useState(
    reclamation && reclamation.answer ? reclamation.answer : ""
  );

  const submitHandler = () => {
    submitAdminAnswer(
      reclamation.reclamationId,
      adminAnswer,
      setReclamations,
      currentUserUid
    );
    setAdminAnswer("");
    setShow(false);
  };
  const deleteHandler = () => {
    deleteReclamation(
      reclamation.reclamationId,
      setReclamations,
      currentUserUid
    );
    setAdminAnswer("");
    setShow(false);
  };

  let content;
  let reclamationInfo;
  if (reclamation) {
    content = (
      <AdminReclamationContent
        setShow={setShow}
        tagOne={reclamation.tagOne}
        tagTwo={reclamation.tagTwo}
        title={reclamation.title}
        description={reclamation.description}
        pictures={reclamation.pictures}
        adminAnswer={adminAnswer}
        existingAnswer={reclamation.answer}
        setAdminAnswer={setAdminAnswer}
        submitHandler={submitHandler}
        deleteHandler={deleteHandler}
      />
    );

    reclamationInfo = (
      <div className={classes.ModalTitle}>
        <p>
          Locataire :{" "}
          <span style={{ color: "#EE9834" }}>
            {/* {getLot(reclamation.locataireId)} */}
            {reclamation.locataireId}
          </span>
        </p>

        <p>{reclamation.dateCreation}</p>
      </div>
    );
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesMUI.modal}
        open={show}
        onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <div className={classes.ModalContent}>
            <div>{reclamationInfo}</div>
            {content}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdminReclamation;
