import React from "react";
import classes from "./Toolbar.module.css";
import * as firebase from "firebase/app";
// MUI
import FaceRoundedIcon from "@material-ui/icons/FaceRounded";
import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// Routing
import { useHistory } from "react-router-dom";
// Animation
import { motion } from "framer-motion";

const Toolbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => window.location.reload());
    history.push("/");
  };

  const history = useHistory();
  const LogoHandler = () => {
    let path = "/";
    history.push(path);
  };
  return (
    <>
      <motion.header className={classes.Header}>
        <header className={classes.Toolbar}>
          <div className={classes.Title}>
            <h3>COREHAB</h3>
            <h4>Orgemont Rénov'</h4>
          </div>
          <div className={classes.Avatar}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <FaceRoundedIcon style={{ color: "white", fontSize: "3rem" }} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push("/profile-setup")}>
                Mon profil
              </MenuItem>
              <MenuItem onClick={handleSignOut}>Se déconnecter</MenuItem>
            </Menu>
          </div>
        </header>
        <div className={classes.BottomSection}>
          <div className={classes.Description}>
            <p>
              Une application où vous pourrez trouver le programme des travaux
              et les dernières informations du chantier. Bonne visite !
            </p>
          </div>
        </div>
      </motion.header>
    </>
  );
};

export default Toolbar;
