export const TravauxReducer = (state, action) => {
  let newContent;

  switch (action.type) {
    case "SET_OPENEDPIN":
      let newPins = [
        ...state.cards.filter((card) => card.id === action.cardID)[0].content[
          action.contentIndex
        ].pins,
      ];
      newPins[action.pinIndex].opened = true;

      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                content: card.content.map((contentObj) =>
                  contentObj.contentId === action.contentId
                    ? {
                        ...contentObj,
                        pins: newPins,
                      }
                    : contentObj
                ),
              }
            : card
        ),
      };

    case "SET_OPENEDPINSCOUNT":
      let pinsCount = 0;
      state.cards
        .filter((card) => card.id === action.cardID)[0]
        .content[action.contentIndex].pins.map((pin) =>
          pin.opened ? pinsCount++ : null
        );
      newContent = [
        ...state.cards.filter((card) => card.id === action.cardID)[0].content,
      ];
      newContent[action.contentIndex] = {
        ...newContent[action.contentIndex],
        openedPinsCount: pinsCount,
      };

      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                content: newContent,
              }
            : card
        ),
      };

    case "UPDATE_RATING":
      newContent = [
        ...state.cards.filter((card) => card.id === action.cardID)[0].content,
      ];
      newContent[action.contentIndex] = {
        ...newContent[action.contentIndex],
        value: action.newValue,
      };

      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                content: newContent,
              }
            : card
        ),
      };

    case "UPDATE_ANSWER":
      newContent = [
        ...state.cards.filter((card) => card.id === action.cardID)[0].content,
      ];
      newContent[action.contentIndex] = {
        ...newContent[action.contentIndex],
        value: action.answer,
      };

      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                content: newContent,
              }
            : card
        ),
      };

    case "UPDATE_IMAGE":
      let newRadioValue = state.cards.filter(
        (card) => card.id === action.cardID
      )[0].content[action.contentIndex].value;
      let newRadioImage = state.cards
        .filter((card) => card.id === action.cardID)[0]
        .content[action.contentIndex].options.find(
          (obj) => obj.value === newRadioValue
        ).image;

      newContent = [
        ...state.cards.filter((card) => card.id === action.cardID)[0].content,
      ];
      newContent[action.contentIndex] = {
        ...newContent[action.contentIndex],
        imageUrl: newRadioImage,
      };

      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                content: newContent,
              }
            : card
        ),
      };

    case "SET_OPENEDCARD":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
              }
            : card
        ),
      };

    case "SET_COMMENT":
      newContent = [
        ...state.cards.filter((card) => card.id === action.cardID)[0].content,
      ];
      newContent[action.contentIndex] = {
        ...newContent[action.contentIndex],
        comment: action.comment,
      };

      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                content: newContent,
              }
            : card
        ),
      };

    case "SET_CONDITIONAL":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                conditional: {
                  ...card.conditional,
                  value: action.value,
                },
              }
            : card
        ),
      };

    case "SET_SUBMITTED":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
              }
            : card
        ),
      };

    case "SET_SELECTEDCARD":
      return {
        ...state,
        selectedCard: action.selectedCard,
      };

    case "SET_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: {
            id: action.id,
            value: action.value,
          },
        },
      };

    case "SET_SHOWCARD":
      return {
        ...state,
        showCard: action.show,
      };

    case "SET_HIDECARD":
      return {
        ...state,
        showCard: action.show,
        selectedCard: "",
      };

    case "LOAD_ANSWERS_C":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                comment: action.comment,
                questions: card.questions.map((question, index) =>
                  question.id === action.questionID
                    ? {
                        ...question,
                        value: action.value,
                      }
                    : question
                ),
              }
            : card
        ),
      };

    case "LOAD_ANSWERS_NC":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                comment: action.comment,
              }
            : card
        ),
      };

    default:
      return state;
  }
};
