import React, { useState } from "react";
import classes from "./Projet.module.css";
// Components
import Articles from "./Articles/Articles";
import UploadDocument from "./UploadDocument/UploadDocument";

const Projet = ({ width, claims, clicked }) => {
  const [pdfs, setPdfs] = useState();
  return (
    <div className={classes.Projet}>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionSvg}>
          <img
            style={{ width: "100%" }}
            alt="hand"
            src={require(`../../../assets/homePage/concertation.svg`)}
          />
        </div>
        <div className={classes.BackgroundShape}></div>
        <div className={classes.Description}>
          <p>
            Dans le cadre de la reprise du patrimoine par{" "}
            <strong>CDC Habitat Social</strong>, en 2021, votre nouveau bailleur
            s’est engagé à réhabiliter vos logements. La première phase de
            réhabilitation débutera mi-2022. 8 bâtiments seront concernés :
          </p>
          <ul>
            <li>Bâtiments rue de Limoges </li>
            <li>Bâtiment square des Dormants</li>
            <li>Bâtiments 20, 34, 50, 58 rue Félix Merlin</li>
            <li>Bâtiment rue de Strasbourg</li>
          </ul>
          <p>
            La durée prévisionnelle de ces travaux est de 2 ans. Vous serez
            régulièrement informés de l’avancée de ces travaux.
          </p>
          <p>
            Pour obtenir plus d’informations sur le projet de rénovation urbaine
            du quartier Orgemont, rendez-vous à{" "}
            <strong>l’Atelier Vert Seine</strong>, la maison du projet !
          </p>
          <div className={classes.ActionButton}>
            <p onClick={clicked}>Accéder aux travaux {">"}</p>
            {/* <div className={classes.ActionButtonSvg}>
              <img
                style={{ width: "100%" }}
                alt="hand"
                src={require(`../../../assets/homePage/main.svg`)}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className={classes.Articles}>
        <div className={classes.ArticlesHeader}>
          <h4 style={{ marginBottom: "1.5rem" }}>Actualités</h4>
          {claims && claims.admin && (
            <UploadDocument setPdfs={setPdfs} claims={claims} />
          )}
        </div>
        <Articles setPdfs={setPdfs} pdfs={pdfs} claims={claims} />
      </div>
    </div>
  );
};

export default Projet;
