import React from "react";

export const travauxCardsData = [
  {
    id: "i1-t1",
    type: "newLayout",
    titleStrong: "Les travaux sur",
    titleWeak: "la façade de votre immeuble",
    description:
      "Refaire la façade de l’immeuble c’est repenser ce qui se voit: l’allure générale de l’immeuble, ses matériaux, son design...et améliorer ce qui ne se voit pas, comme l’isolation, pour gagner en confort thermique à l’intérieur de votre logement. Pour en savoir plus sur les différents travaux cliquez sur explorer.",
    thumbnail: "i1-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Refaire la façade de l’immeuble c’est repenser ce qui se voit: l’allure générale de l’immeuble, ses matériaux, son design...et améliorer ce qui ne se voit pas, comme l’isolation, pour gagner en confort thermique à l’intérieur de votre logement. Pour en savoir plus sur les différents travaux cliquez sur explorer.",
        title: "Les travaux sur la façade de votre immeuble",
        video: {
          url: "https://youtu.be/jW_Opfuupkc",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i1-t2",
    type: "newLayout",
    titleStrong: "Les travaux sur",
    titleWeak: "la façade de votre immeuble",
    description:
      "Refaire la façade de l’immeuble c’est repenser ce qui se voit: l’allure générale de l’immeuble, ses matériaux, son design...et améliorer ce qui ne se voit pas, comme l’isolation, pour gagner en confort thermique à l’intérieur de votre logement. Pour en savoir plus sur les différents travaux cliquez sur explorer.",
    thumbnail: "i1-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Refaire la façade de l’immeuble c’est repenser ce qui se voit: l’allure générale de l’immeuble, ses matériaux, son design...et améliorer ce qui ne se voit pas, comme l’isolation, pour gagner en confort thermique à l’intérieur de votre logement. Pour en savoir plus sur les différents travaux cliquez sur explorer.",
        title: "Les travaux sur la façade de votre immeuble",
        video: {
          url: "https://youtu.be/92JO-cRwqtQ",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["b"],
  },
  {
    id: "i1-t3",
    type: "newLayout",
    titleStrong: "Les travaux sur",
    titleWeak: "la façade de votre immeuble",
    description:
      "Refaire la façade de l’immeuble c’est repenser ce qui se voit: l’allure générale de l’immeuble, ses matériaux, son design...et améliorer ce qui ne se voit pas, comme l’isolation, pour gagner en confort thermique à l’intérieur de votre logement. Pour en savoir plus sur les différents travaux cliquez sur explorer.",
    thumbnail: "i1-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Refaire la façade de l’immeuble c’est repenser ce qui se voit: l’allure générale de l’immeuble, ses matériaux, son design...et améliorer ce qui ne se voit pas, comme l’isolation, pour gagner en confort thermique à l’intérieur de votre logement. Pour en savoir plus sur les différents travaux cliquez sur explorer.",
        title: "Les travaux sur la façade de votre immeuble",
        video: {
          url: "https://youtu.be/jW_Opfuupkc",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["c"],
  },
  {
    id: "i2-t3",
    type: "newLayout",
    titleStrong: "Les travaux sur",
    titleWeak: "le parking",
    description:
      "Ces travaux consistent à remettre à neuf l’espace parking de votre immeuble. Pour en savoir plus à ce sujet, cliquez sur explorer.",
    thumbnail: "i2-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux consistent à remettre à neuf l’espace parking de votre immeuble. Pour en savoir plus à ce sujet, cliquez sur explorer.",
        title: "Les travaux sur le parking",
        video: {
          url: "https://youtu.be/oLi9AqsNYzo",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["c"],
  },
  {
    id: "i4-t1",
    type: "newLayout",
    titleStrong: "Les parties communes",
    titleWeak: "de votre immeuble",
    description:
      "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
    thumbnail: "i4-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
        title: "Les parties communes de votre immeuble.",
        video: {
          url: "https://youtu.be/aknpndsRi-c",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i4-t2",
    type: "newLayout",
    titleStrong: "Les parties communes",
    titleWeak: "de votre immeuble",
    description:
      "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
    thumbnail: "i4-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
        title: "Les parties communes de votre immeuble.",
        video: {
          url: "https://youtu.be/pJ9iYu4zhbE",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["b"],
  },
  {
    id: "i4-t3",
    type: "newLayout",
    titleStrong: "Les parties communes",
    titleWeak: "de votre immeuble",
    description:
      "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
    thumbnail: "i4-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
        title: "Les parties communes de votre immeuble.",
        video: {
          url: "https://youtu.be/pJ9iYu4zhbE",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["c"],
  },
  {
    id: "i5-t1",
    type: "newLayout",
    titleStrong: "Les travaux techniques",
    titleWeak: "et de sécurité",
    description:
      "Vous trouverez ici tous les travaux réglementaires d’ordre technique ou de sécurité. Ils ont pour but de prémunir contre les incendies, les incidents liés à l’électricité (électrocution, coupures…) ou à l’eau. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i5-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Vous trouverez ici tous les travaux réglementaires d’ordre technique ou de sécurité. Ils ont pour but de prémunir contre les incendies, les incidents liés à l’électricité (électrocution, coupures…) ou à l’eau. Pour en savoir plus, cliquez sur explorer.",
        title: "Les travaux techniques et de sécurité dans votre immeuble",
        video: {
          url: "https://youtu.be/xNcY6765whw",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i5-t2",
    type: "newLayout",
    titleStrong: "Les travaux techniques",
    titleWeak: "et de sécurité",
    description:
      "Vous trouverez ici tous les travaux réglementaires d’ordre technique ou de sécurité. Ils ont pour but de prémunir contre les incendies, les incidents liés à l’électricité (électrocution, coupures…) ou à l’eau. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i5-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Vous trouverez ici tous les travaux réglementaires d’ordre technique ou de sécurité. Ils ont pour but de prémunir contre les incendies, les incidents liés à l’électricité (électrocution, coupures…) ou à l’eau. Pour en savoir plus, cliquez sur explorer.",
        title: "Les travaux techniques et de sécurité dans votre immeuble",
        video: {
          url: "https://youtu.be/IqGt9bCKVvM",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["b"],
  },
  {
    id: "i5-t3",
    type: "newLayout",
    titleStrong: "Les travaux techniques",
    titleWeak: "et de sécurité",
    description:
      "Vous trouverez ici tous les travaux réglementaires d’ordre technique ou de sécurité. Ils ont pour but de prémunir contre les incendies, les incidents liés à l’électricité (électrocution, coupures…) ou à l’eau. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i5-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Vous trouverez ici tous les travaux réglementaires d’ordre technique ou de sécurité. Ils ont pour but de prémunir contre les incendies, les incidents liés à l’électricité (électrocution, coupures…) ou à l’eau. Pour en savoir plus, cliquez sur explorer.",
        title: "Les travaux techniques et de sécurité dans votre immeuble",
        video: {
          url: "https://youtu.be/IqGt9bCKVvM",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["c"],
  },
  {
    id: "i6-t1",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "dans votre logement",
    description:
      "Sols, murs, plafonds, équipements, électricité, eau...votre logement fait peau neuve afin d’améliorer votre confort et faire baisser vos dépenses énergétiques. Pour vous informer de tous les travaux qui auront lieu dans votre logement, cliquez sur explorer.",
    thumbnail: "i6-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Sols, murs, plafonds, équipements, électricité, eau...votre logement fait peau neuve afin d’améliorer votre confort et faire baisser vos dépenses énergétiques. Pour vous informer de tous les travaux qui auront lieu dans votre logement, cliquez sur explorer.",
        title: "Les travaux dans votre logement",
        video: {
          url: "https://youtu.be/ZGG9KyXcfic",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i6-t2",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "dans votre logement",
    description:
      "Équipements, électricité, eau...votre logement fait peau neuve afin d’améliorer votre confort et faire baisser vos dépenses énergétiques. Pour vous informer de tous les travaux qui auront lieu dans votre logement, cliquez sur explorer.",
    thumbnail: "i6-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Équipements, électricité, eau...votre logement fait peau neuve afin d’améliorer votre confort et faire baisser vos dépenses énergétiques. Pour vous informer de tous les travaux qui auront lieu dans votre logement, cliquez sur explorer.",
        title: "Les travaux dans votre logement",
        video: {
          url: "https://youtu.be/CUbhsE4TBxY",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["b"],
  },
  {
    id: "i6-t3",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "dans votre logement",
    description:
      "Équipements, électricité, eau...votre logement fait peau neuve afin d’améliorer votre confort et faire baisser vos dépenses énergétiques. Pour vous informer de tous les travaux qui auront lieu dans votre logement, cliquez sur explorer.",
    thumbnail: "i6-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Équipements, électricité, eau...votre logement fait peau neuve afin d’améliorer votre confort et faire baisser vos dépenses énergétiques. Pour vous informer de tous les travaux qui auront lieu dans votre logement, cliquez sur explorer.",
        title: "Les travaux dans votre logement",
        video: {
          url: "https://youtu.be/nDQNzcnBjVM",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["c"],
  },

  // {
  //   id: "c1",
  //   type: "newLayout",
  //   titleStrong: "Personnaliser la façade",
  //   titleWeak: "de votre immeuble",
  //   description:
  //     "La révision de l’isolation par l’extérieur de votre immeuble sera l’occasion de revoir le style de la façade et par la même occasion l’aspect général de votre immeuble. S’il appartient aux architectes de dessiner cette nouvelle façade, vous pouvez grâce à cette fiche choisir parmi certains styles sélectionnés. Votre choix guidera les concepteurs pour la réalisation de la nouvelle façade de votre immeuble.",
  //   thumbnail: "Base-facade.svg",
  //   submitted: false,
  //   content: [
  //     {
  //       contentId: "content-1",
  //       type: "radio",
  //       title: "Personnaliser la façade de votre immeuble",
  //       question:
  //         "Parmi les styles de façades proposés ci-dessous, lequel vous semblerait-il le plus convenir à vos attentes ?",
  //       imageUrl: "Base-facade.svg",
  //       value: "",
  //       comment: "",
  //       options: [
  //         {
  //           label: "Pierre / brique",
  //           value: "rep1",
  //           image: "briques.svg",
  //         },
  //         {
  //           label: "Bois",
  //           value: "rep2",
  //           image: "c1-Bois.svg",
  //         },
  //         {
  //           label: "Alu",
  //           value: "rep3",
  //           image: "alu.svg",
  //         },
  //         {
  //           label: "Enduit",
  //           value: "rep4",
  //           image: "Enduit.svg",
  //         },
  //         {
  //           label: "Végétalisée",
  //           value: "rep5",
  //           image: "végétalisé.svg",
  //         },
  //       ],
  //     },
  //   ],
  //   tags: "Personnalisable",
  //   groupe: ["a", "b", "c"],
  // },
  {
    id: "c2",
    type: "newLayout",
    titleStrong: "Personnaliser les pieds",
    titleWeak: "de votre immeuble",
    description:
      "Aménager les pieds de votre immeuble, permettra d’avoir en bas de chez vous un petit bout de jardin entretenu. Si l’intérêt d’aménager les pieds d’immeuble est en premier lieu esthétique, cela permet de créer de la distance avec la rue et protéger un peu plus les logements situés en RDC. Grâce à cette fiche vous pourrez donner vos préférences sur l’aménagement des pieds de votre immeuble.",
    thumbnail: "Pied-d-immeuble-entrée.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser les pieds de votre immeuble",
        question:
          "Parmi ces choix d’ambiances végétales, lequel à votre préférence ?",
        imageUrl: "Pied-d-immeuble-entrée.svg",
        value: "",
        comment: "",
        options: [
          {
            label:
              "Des noues en bordure de l’espace privé avec des herbes hautes.",
            value: "rep1",
            image: "noues.svg",
          },
          {
            label: "Des fleurs.",
            value: "rep2",
            image: "fleurs.svg",
          },
          {
            label:
              "Des plantes persistantes qui gardent leurs feuilles même en hiver.",
            value: "rep3",
            image: "plantes-persistantes.svg",
          },
          {
            label: "Des arbres fruitiers.",
            value: "rep4",
            image: "arbres-fruitiers.svg",
          },
          {
            label: "Des jardins potagers.",
            value: "rep5",
            image: "jardin-potager.svg",
          },
          {
            label: "Des plantes comestibles.",
            value: "rep6",
            image: "plantes-comestibles.svg",
          },
          {
            label: "Du mobilier.",
            value: "rep7",
            image: "mobilier.svg",
          },
          {
            label: "Des herbes sauvages.",
            value: "rep8",
            image: "herbes-sauvages.svg",
          },
          {
            label: "J’ai d'autres idées..",
            value: "rep100",
            image: "autre.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c3",
    type: "newLayout",
    titleStrong: "Personnaliser le hall",
    titleWeak: "de votre immeuble",
    description:
      "Le hall de votre immeuble fait peau neuve! (Reportez-vous à la fiche information sur ce sujet pour en savoir plus.) Pour cela nous avons besoin de vous. Grâce à cette fiche, vous pourrez configurer l’aspect général du hall de votre immeuble. Vos choix seront par la suite étudiés par les concepteurs pour le dessin du futur hall.",
    thumbnail: "Hall-de-base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser le hall de votre immeuble",
        question:
          "Quelle est - selon vous - l’ambiance de couleur pour les murs et plafonds qui conviendrait le mieux pour le hall de votre immeuble ?",
        imageUrl: "Hall-de-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Blanc.",
            value: "rep1",
            image: "Clair.svg",
          },
          {
            label: "Pastel.",
            value: "rep2",
            image: "Pastel.svg",
          },
          {
            label: "Couleurs vives.",
            value: "rep3",
            image: "Vif.svg",
          },
          {
            label: "Couleurs sombres et chics.",
            value: "rep4",
            image: "Sombre-chic.svg",
          },
          {
            label: "Quelques touches de couleurs ici et là.",
            value: "rep5",
            image: "Touches-de-couleur.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "Hall-Autre-idée.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c4",
    type: "newLayout",
    titleStrong: "Personnaliser",
    titleWeak: "votre cage d’escalier",
    description:
      "La cage d’escalier de votre immeuble fait peau neuve! (Reportez-vous à la fiche information sur ce sujet pour en savoir plus.) Pour cela nous avons besoin de vous. Grâce à cette fiche, vous pourrez configurer l’aspect général de la cage d’escalier de votre immeuble. Vos choix seront par la suite étudiés par les concepteurs pour le dessin de la future cage.",
    thumbnail: "Cage-d-escalier-BASE.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser votre cage d’escalier",
        question:
          "Quelle est - selon vous - l’ambiance de couleur pour les murs et plafonds qui conviendrait le mieux pour le hall de votre immeuble ?",
        imageUrl: "Cage-d-escalier-BASE.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Blanc.",
            value: "rep1",
            image: "Clair.svg",
          },
          {
            label: "Pastel.",
            value: "rep2",
            image: "Pastel.svg",
          },
          {
            label: "Couleurs vives.",
            value: "rep3",
            image: "Vif.svg",
          },
          {
            label: "Couleurs sombres et chics.",
            value: "rep4",
            image: "Sombre-chic.svg",
          },
          {
            label: "Quelques touches de couleurs ici et là.",
            value: "rep5",
            image: "Touches-de-couleur.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "Hall-Autre-idée.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c5",
    type: "newLayout",
    titleStrong: "Personnaliser",
    titleWeak: "votre nouvel ascenseur",
    description:
      "Les travaux de réhabilitation prévoient de remplacer vos vieux ascenseurs par des ascenseurs neufs. Dans cette intervention de confort, de modernisation et de sécurité, nous vous proposons de personnaliser le style du futur ascenseur de votre immeuble. Vos préférences ainsi que celles des autres locataires seront prises en compte pour le choix de l’ascenseur par les concepteurs.",
    thumbnail: "Ascenseur-de-base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser votre nouvel ascenseur",
        question:
          "Quelle est votre préférence pour l’esthétique du nouvel ascenseur ?",
        imageUrl: "Ascenseur-de-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Argentée.",
            value: "rep1",
            image: "c5-Argenté.svg",
          },
          {
            label: "Couleurs sombres et chics.",
            value: "rep2",
            image: "c5-Chic-et-sombre.svg",
          },
          {
            label: "Coloris bois.",
            value: "rep3",
            image: "c5-Bois.svg",
          },
          {
            label: "Touches de couleurs.",
            value: "rep4",
            image: "c5-touches-de-couleur.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep5",
            image: "Ascenseur-Autre-idée.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c6",
    type: "newLayout",
    titleStrong: "Votre opinion sur",
    titleWeak: "le devenir des caves",
    description:
      "Le devenir des caves est un sujet important dans les travaux de réhabilitation de votre immeuble. Si pour certains locataires les caves sont essentielles pour stocker des meubles, des affaires...pour d’autres, elles sont mal exploitées, peu accessibles voire inutiles en l’état. Pour cela, nous aimerions en savoir plus sur l’usage que vous avez de votre cave aujourd’hui afin de nous aider à mieux cerner les enjeux de leur devenir.",
    thumbnail: "Cave-Base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Votre opinion sur le devenir des caves",
        question:
          "En premier lieu, possédez-vous une cave dans votre immeuble ?",
        imageUrl: "Cave-Base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui",
            value: "rep1",
            image: "Cave-Base.svg",
          },
          {
            label: "Non",
            value: "rep2",
            image: "Cave-Base.svg",
            skip: "close",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "rating",
        title: "Votre opinion sur le devenir des caves",
        question:
          "Dans un souci de comprendre l’état général des caves, quelle note leur donneriez-vous aujourd’hui ?",
        imageUrl: "Cave-Base.svg",
        value: 0,
        comment: "",
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Votre opinion sur le devenir des caves",
        question: "Avez-vous un usage fréquent de votre cave aujourd’hui ?",
        imageUrl: "Cave-Base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui",
            value: "rep1",
            image: "Cave-Base.svg",
            skip: 4,
          },
          {
            label: "Non",
            value: "rep2",
            image: "Cave-Base.svg",
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Votre opinion sur le devenir des caves",
        question: "Pourquoi n’utilisez-vous pas la cave de votre immeuble ?",
        imageUrl: "Cave-condamnée-planches.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Je n’en ai pas l’utilité",
            value: "rep1",
            image: "Cave-condamnée-planches.svg",
          },
          {
            label: "Problème d’insécurité",
            value: "rep2",
            image: "Cave-condamnée-planches.svg",
          },
          {
            label: "Problème de vétusté",
            value: "rep3",
            image: "Cave-condamnée-planches.svg",
          },
          {
            label: "Autre",
            value: "rep100",
            image: "Cave-condamnée-planches.svg",
          },
        ],
      },
      {
        contentId: "content-5",
        type: "radio",
        title: "Votre opinion sur le devenir des caves",
        question: "Quel(s) usage(s) avec-vous de votre cave ?",
        imageUrl: "Cave-condamnée-planches.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Stockage",
            value: "rep1",
            image: "Cave-condamnée-planches.svg",
          },
          {
            label: "Local vélos / poussettes",
            value: "rep2",
            image: "Cave-condamnée-planches.svg",
          },
          {
            label: "Bricolage",
            value: "rep3",
            image: "Cave-condamnée-planches.svg",
          },
          {
            label: "Autre",
            value: "rep100",
            image: "Cave-condamnée-planches.svg",
          },
        ],
      },
      {
        contentId: "content-6",
        type: "radio",
        title: "Votre opinion sur le devenir des caves",
        question: "Comment envisagez-vous l’amélioration des caves ?",
        imageUrl: "Cave-question-ouverte.svg",
        value: "rep100",
        comment: "",
        options: [
          {
            label: "Réponse ouverte",
            value: "rep100",
            image: "Cave-question-ouverte.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c7",
    type: "newLayout",
    titleStrong: "Votre opinion sur",
    titleWeak: "la création de locaux communs",
    description:
      "Les locaux communs permettent de fournir à chacun des résidents des espaces de services en commun qui peuvent faciliter leur quotidien. Dans cette idée, les travaux de réhabilitation sont l’occasion de repenser ces espaces communs afin qu’ils soient aux plus proches de vos besoins. (L’aménagement de ces locaux dépendra des possibilités de mise en œuvre) Cette fiche vous permettra de les exprimer.",
    thumbnail: "Local-custom.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Votre opinion sur la création de locaux communs",
        question:
          "Combien de poussettes avez-vous dans votre foyer? (cela comprend les poussettes des enfants que vous pouvez garder si vous faites de la garderie)",
        imageUrl: "Local-à-poussette.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Aucune poussette",
            value: "rep1",
            image: "Local-à-poussette.svg",
            skip: 2,
          },
          {
            label: "Une poussette",
            value: "rep2",
            image: "Local-à-poussette.svg",
          },
          {
            label: "Deux poussettes",
            value: "rep3",
            image: "Local-à-poussette.svg",
          },
          {
            label: "Trois poussettes ou plus",
            value: "rep4",
            image: "Local-à-poussette.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Votre opinion sur la création de locaux communs",
        question:
          "S’il n’y a pas de places dans les locaux communs existants ou si ces derniers n’existent pas, où rangez-vous votre/vos poussette(s) ?",
        imageUrl: "Local-à-poussette.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Dans un local commun",
            value: "rep1",
            image: "Local-à-poussette.svg",
          },
          {
            label: "Chez moi",
            value: "rep2",
            image: "Local-à-poussette.svg",
          },
          {
            label: "Dans le couloir devant ma porte",
            value: "rep3",
            image: "Local-à-poussette.svg",
          },
          {
            label: "Autre",
            value: "rep100",
            image: "Local-à-poussette.svg",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Votre opinion sur la création de locaux communs",
        question:
          "combien de vélos avez-vous dans votre foyer ? (cela comprend les vélos, tricycles et trottinettes)",
        imageUrl: "Local-à-vélo.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Aucune vélo",
            value: "rep1",
            image: "Local-à-vélo.svg",
          },
          {
            label: "Un vélo",
            value: "rep2",
            image: "Local-à-vélo.svg",
          },
          {
            label: "Deux vélos",
            value: "rep3",
            image: "Local-à-vélo.svg",
          },
          {
            label: "Trois vélos ou plus",
            value: "rep4",
            image: "Local-à-vélo.svg",
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Votre opinion sur la création de locaux communs",
        question:
          "Une des principales craintes des résidents concernant le fait d'entreposer dans un local public son ou ses vélos (voire d’en posséder un à l’avenir) est la sécurité. Aussi, dans le cas où un local vélo sécurisé était mis à disposition, pourriez-vous à l’avenir en avoir l’utilité ?",
        imageUrl: "Local-à-vélo.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui",
            value: "rep1",
            image: "Local-à-vélo.svg",
          },
          {
            label: "Non",
            value: "rep2",
            image: "Local-à-vélo.svg",
          },
          {
            label: "Je ne sais pas encore",
            value: "rep3",
            image: "Local-à-vélo.svg",
          },
        ],
      },
      {
        contentId: "content-5",
        type: "radio",
        title: "Votre opinion sur la création de locaux communs",
        question: "Enfin, de quels locaux communs auriez-vous le plus besoin ?",
        imageUrl: "Local-custom.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Local vélos",
            value: "rep1",
            image: "Local-à-vélo.svg",
          },
          {
            label: "Local poussettes",
            value: "rep2",
            image: "Local-à-poussette.svg",
          },
          {
            label: "Autre",
            value: "rep100",
            image: "Local-custom.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c8",
    type: "newLayout",
    titleStrong: "Personnaliser les",
    titleWeak: "fenêtres de votre logement",
    description:
      "Avec les travaux sur la façade de votre immeuble, les fenêtres seront changées pour être plus performantes thermiquement et plus lumineuses pour l’intérieur de votre logement. Mais avec le changement des fenêtres se posent une série de questions sur vos besoins à ce sujet: quel type d’ouverture, d’occultation, répondrait le mieux à votre usage ? Pour y répondre, ouvrez cette fiche !",
    thumbnail: "Double-battant.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser les fenêtres de votre logement",
        question:
          "La manière dont s’ouvre une fenêtre n’est pas anodine. Certains préfèreront les fenêtres qu’on peut ouvrir en grand, d’autres en revanche trouveront ces types de fenêtres risquées. Notamment pour les enfants en bas âge...Quel type d’ouverture souhaiteriez-vous pour vos fenêtres ?",
        imageUrl: "Double-battant.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Oscillo battant.",
            value: "rep1",
            image: "Oscillo-battant.png",
          },
          {
            label: "A la française (Double-battant).",
            value: "rep2",
            image: "Double-battant.png",
          },
          {
            label: "Coulissant.",
            value: "rep3",
            image: "Coulissant.png",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Personnaliser les fenêtres de votre logement",
        question:
          "Les allèges sont les parties basses des fenêtres, généralement séparées par un bois horizontal. Ces allèges peuvent être entièrement vitrées, laissant ainsi passer un maximum de lumière. Ou complètement opaques pour préserver une certaine intimité. Quel type d’allège correspond le plus à vos attentes ?",
        imageUrl: "Allège-vitrée.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Transparente.",
            value: "rep1",
            image: "Allège-vitrée.png",
          },
          {
            label: "Semi-transparente.",
            value: "rep2",
            image: "Allège-semi-transparente.png",
          },
          {
            label: "Opaque.",
            value: "rep3",
            image: "Allège-opaque.png",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Personnaliser les fenêtres de votre logement",
        question:
          "Les travaux de réhabilitation prévoient de changer l’ensemble des occultations sur les fenêtres qui donnent sur vos pièces de vie. (Chambres et salon) Dans les choix proposés, quel est celui qui à votre préférence ?",
        imageUrl: "Volet-accordéon.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Persiennes en accordéons.",
            value: "rep1",
            image: "Volet-accordéon.png",
          },
          {
            label: "Volets manuels (sous réserve de faisabilité technique).",
            value: "rep2",
            image: "Volets-manuels.png",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c9",
    type: "newLayout",
    titleStrong: "Personnaliser les",
    titleWeak: "murs de votre logement",
    description:
      "Les travaux de réhabilitation prévoient de remettre à neuf votre logement. Dans le cadre de ces travaux, nous vous proposons ici de vous exprimer sur la couleur des murs de votre logement. Pour cela, cliquez sur personnaliser.",
    thumbnail: "c9-Murs-base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser les murs de votre logement",
        question:
          "Quelle ambiance de couleur préférez-vous pour les murs de votre logement ?",
        imageUrl: "c9-Murs-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label:
              "Entièrement blanc, pour une base neutre dans votre logement.",
            value: "rep1",
            image: "c9-Blanc.svg",
          },
          {
            label:
              "Pastel très clair, dont la nuance sera définie par l’architecte.",
            value: "rep2",
            image: "c9-Pastel.svg",
          },
          {
            label:
              "Quelques touches de couleurs ici et là, qui seront définies par l’architecte.",
            value: "rep3",
            image: "c9-Touches-de-couleur.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c10",
    type: "newLayout",
    titleStrong: "Personnaliser le",
    titleWeak: "nouveau sol de votre logement",
    description:
      "Les travaux de réhabilitation prévoient de remettre à neuf votre logement, en refaisant notamment tous les revêtements de sol. Si un sol de type “sol” souple sera généralisé dans l’ensemble de votre logement. Nous vous proposons ici de vous exprimer sur les motifs et donc la couleur de ces derniers . Pour cela, cliquez sur personnaliser.",
    thumbnail: "c10-Sol-base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser le nouveau sol de votre logement",
        question: "Quel type de sol souple préférez-vous pour votre logement ?",
        imageUrl: "c10-Sol-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Imitation parquet bois clair.",
            value: "rep1",
            image: "c10-imitation-bois-clair.png",
          },
          {
            label: "Imitation parquet bois foncé.",
            value: "rep2",
            image: "c10-imitation-bois-sombre.png",
          },
          {
            label: "Imitation béton ciré.",
            value: "rep3",
            image: "c10-béton-ciré.png",
          },
          {
            label: "Motif carrelage.",
            value: "rep5",
            image: "c10-carrelage-motif.png",
          },
          {
            label: "Motif carrelage marbré.",
            value: "rep6",
            image: "c10-carrelage-naturel-marbré.png",
          },
          {
            label: "Couleur neutre.",
            value: "rep7",
            image: "c10-couleur-neutre.png",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c11",
    type: "newLayout",
    titleStrong: "Personnaliser la",
    titleWeak: "salle de bain de votre logement",
    description:
      "Les travaux de réhabilitation prévoient de remettre à neuf votre logement. Dans cet objectif, les faïences de votre salle de bain seront refaites. Nous vous proposons ici de vous exprimer le style et la couleur de ces dernières. Pour cela, cliquez sur personnaliser.",
    thumbnail: "c11-SDB-Baignoire.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser la salle de bain de votre logement",
        question:
          "Quel type de carreaux et de couleur de carreaux souhaiteriez vous avoir pour la nouvelle faïence de votre salle de bain ?",
        imageUrl: "c11-SDB-Baignoire.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Petit carreaux blancs.",
            value: "rep1",
            image: "c11-petits-carreaux-blancs.svg",
          },
          {
            label: "Petits carreaux pastelles.",
            value: "rep2",
            image: "c11-petits-carreaux-pastel.svg",
          },
          {
            label:
              "Peits carreaux avec quelques touches de couleurs ici et là.",
            value: "rep3",
            image: "c11-petits-carreaux-couleurs.svg",
          },
          {
            label: "Grands carreaux blancs.",
            value: "rep4",
            image: "c11-grand-carreaux-blancs.svg",
          },
          {
            label: "Grands carreaux pastelles.",
            value: "rep5",
            image: "c11-grands-carreaux-pastel.svg",
          },
          {
            label:
              "Grands carreaux avec quelques touches de couleurs ici et là.",
            value: "rep6",
            image: "c11-grands-carreaux-couleurs.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c12",
    type: "newLayout",
    titleStrong: "Personnaliser les",
    titleWeak: "équipements de votre salle de bain",
    description:
      "Les travaux de réhabilitation prévoient de remettre à neuf votre logement. Dans cet objectif, les équipements sanitaires de votre salle de bain pourraient être remplacés. C’est le cas des baignoires qui pourraient être transformées en douche adaptée PMR. (sur justification du locataire) Nous vous proposons ici de vous exprimer sur vos besoins en équipement. Pour cela, cliquez sur personnaliser.",
    thumbnail: "c12-SDB-Base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser les équipements de votre salle de bain",
        question:
          "Douche ou baignoire ? Il y a autant d’arguments valables pour le choix d’une douche que pour le choix d’une baignoire. Cela dépend avant tout de vos besoins et de vos goûts. Quel type d’équipement sanitaire convient le mieux à votre usage ?",
        imageUrl: "c12-SDB-Base.svg",
        value: "",
        comment: "",
        options: [
          {
            label:
              "Douche adaptée PMR. (Ce choix doit se justifier par l’âge ou l’handicap)",
            value: "rep1",
            image: "c12-SDB-Douche.svg",
          },
          {
            label: "Baignoire.",
            value: "rep2",
            image: "c12-SDB-Baignoire.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c13",
    type: "newLayout",
    titleStrong: "Personnaliser les",
    titleWeak: "équipements de votre cuisine",
    description:
      "Les travaux de réhabilitation prévoient de remettre à neuf votre logement. Dans cet objectif, les équipements de votre cuisine seront remplacés. C’est le cas des bacs d’évier. Nous vous proposons ici de vous exprimer sur vos besoins concernant cet équipement. Pour cela, cliquez sur personnaliser.",
    thumbnail: "c13-Cuisine-base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser les équipements de votre cuisine",
        question:
          "Dans l’idéal, quel type d’évier de cuisine convient le mieux à votre usage ? (La faisabilité est à confirmer au cas par cas selon la configuration de votre cuisine)",
        imageUrl: "c13-Cuisine-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Simple bac",
            value: "rep1",
            image: "c13-Vasque-simple.svg",
          },
          {
            label: "Un bac et demi.",
            value: "rep2",
            image: "c13-Vasque-une-et-demi-1.svg",
          },
          {
            label: "Double bac.",
            value: "rep3",
            image: "c13-Vasque-double.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c14",
    type: "newLayout",
    titleStrong: "Personnaliser le",
    titleWeak: "square des Dormans",
    description:
      "Le square des Dormans est aujourd’hui sous-exploité mais représente un véritable lieu de rencontre et d’activité en devenir! Si vous avez des idées pour aménager le square des Dormans, vous êtes sur la bonne fiche. Cliquez sur personnaliser pour donner votre avis à ce sujet.",
    thumbnail: "c14-custom.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser le square des Dormans",
        question:
          "Les possibilités d’aménagement de ce square sont nombreuses et ne dépendent que de vous: de vos besoins, de vos goûts et de vos envies. Quelle type d’activité vous semble le plus convenir à cet espace ?",
        imageUrl: "c14-custom.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un espace picnics.",
            value: "rep1",
            image: "c14-pique-nique.svg",
          },
          {
            label: "Un espace de repos.",
            value: "rep2",
            image: "c14-repos-adulte.svg",
          },
          {
            label: "Un espace de balade.",
            value: "rep3",
            image: "c14-ballade-et-détente.svg",
          },
          {
            label: "Une pelouse libre pour toutes les activités.",
            value: "rep4",
            image: "c14-multi-activité.svg",
          },
          {
            label: "Une aire de jeux pour enfantss.",
            value: "rep5",
            image: "c14-jeux-pour-enfants.svg",
          },
          {
            label: "Un espace de services (Type foodtruck).",
            value: "rep6",
            image: "c14-foodtruck.svg",
          },
          {
            label: "Un parcours sportif.",
            value: "rep7",
            image: "c14-disséminé.svg",
          },
          {
            label: "Un terrain de pétanque.",
            value: "rep8",
            image: "c14-pétanque.svg",
          },
          {
            label: "Un espace paysagé.",
            value: "rep9",
            image: "c14-paysagère.svg",
          },
          {
            label: "Une zone avec des mobiliers partagés.",
            value: "rep10",
            image: "c14-mobiliers-de-partage.svg",
          },
          {
            label: "Un lieu de biodiversité et de pédagogie.",
            value: "rep11",
            image: "c14-parcours-découverte.svg",
          },
          {
            label: "Une zone d’exposition en plein air.",
            value: "rep12",
            image: "c14-zone-d-exposition.svg",
          },
          {
            label: "Des jardins partagés.",
            value: "rep13",
            image: "c14-Jardins-partagés.svg",
          },
          {
            label: "J’ai d’autres idées.",
            value: "rep100",
            image: "c14-custom.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
  {
    id: "c15",
    type: "newLayout",
    titleStrong: "Vos",
    titleWeak: "mobilités",
    description:
      "Pour mieux cerner vos besoins en termes de places de parking voitures et deux roues, nous vous proposons dans cette fiche une courte enquête sur vos mobilités. Pour répondre à l’enquête cliquez sur personnaliser.",
    thumbnail: "c15-base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Vos mobilités",
        question: "De combien de voiture disposez-vous dans votre foyer ?",
        imageUrl: "c15-q1-custom.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "1 voiture",
            value: "rep1",
            image: "c15-q1-1-voiture.svg",
          },
          {
            label: "2 voitures",
            value: "rep2",
            image: "c15-q1-2-voitures.svg",
          },
          {
            label: "3 voitures",
            value: "rep3",
            image: "c15-q1-3-voitures-plus.svg",
          },
          {
            label: "Aucune voiture",
            value: "rep4",
            image: "c15-q1-aucune-voiture.svg",
            skip: 4,
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Vos mobilités",
        question:
          "A terme, seriez-vous intéressé d’avoir une place attitrée sur un parking ?",
        imageUrl: "c15-q2-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui",
            value: "rep1",
            image: "c15-q2-attitre.svg",
          },
          {
            label: "Non",
            value: "rep2",
            image: "c15-q2-non-attitre.svg",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Vos mobilités",
        question:
          "A terme, seriez-vous prêt à payer pour disposer d’une place attitrée sur un parking ?",
        imageUrl: "c15-q3-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui",
            value: "rep1",
            image: "c15-q3-oui.svg",
          },
          {
            label: "Non",
            value: "rep2",
            image: "c15-q3-non.svg",
            skip: 4,
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Vos mobilités",
        question: "Si oui, combien seriez-vous prêt à payer par mois ?",
        imageUrl: "c15-q4-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Moins de 10 € / mois",
            value: "rep1",
            image: "c15-q4-10.svg",
          },
          {
            label: "Entre 10 et 20 € / mois",
            value: "rep2",
            image: "c15-q4-10-20.svg",
          },
          {
            label: "20 € / mois",
            value: "rep3",
            image: "c15-q4-20.svg",
          },
          {
            label: "Je ne sais pas",
            value: "rep4",
            image: "c15-q4-NSP.svg",
          },
        ],
      },
      {
        contentId: "content-5",
        type: "radio",
        title: "Vos mobilités",
        question: "Disposez-vous de 2 roues  (moto / scooter) ?",
        imageUrl: "c15-q5-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Non",
            value: "rep1",
            image: "c15-q5-non.svg",
            skip: 6,
          },
          {
            label: "Oui: 1 ou plusieurs 2 roues",
            value: "rep2",
            image: "c15-q5-oui.svg",
          },
        ],
      },
      {
        contentId: "content-6",
        type: "radio",
        title: "Vos mobilités",
        question:
          "Seriez-vous intéressés par des places pour garer votre 2 roues (moto / scooter) en extérieur ?",
        imageUrl: "c15-q6-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Non",
            value: "rep1",
            image: "c15-q6-non.svg",
          },
          {
            label: "Oui: 1 ou plusieurs 2 roues",
            value: "rep2",
            image: "c15-q6-oui.svg",
          },
        ],
      },
      {
        contentId: "content-7",
        type: "radio",
        title: "Vos mobilités",
        question:
          "Évolution des mobilités : envisagez-vous, dans les années à venir, d’acquérir une voiture électrique ?",
        imageUrl: "c15-q7-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, je l’envisage",
            value: "rep1",
            image: "c15-q7-oui.svg",
          },
          {
            label: "Non",
            value: "rep2",
            image: "c15-q7-non.svg",
          },
          {
            label: "Je ne sais pas",
            value: "rep3",
            image: "c15-q7-NSP.svg",
          },
        ],
      },
      {
        contentId: "content-8",
        type: "radio",
        title: "Vos mobilités",
        question:
          "Évolution des mobilités : envisagez-vous, dans les années à venir, de vous séparer de votre voiture (ou de l’une de vos voitures) ?",
        imageUrl: "c15-q8-base.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, je l’envisage",
            value: "rep1",
            image: "c15-q8-oui.svg",
          },
          {
            label: "Non",
            value: "rep2",
            image: "c15-q8-non.svg",
          },
          {
            label: "Je ne sais pas",
            value: "rep3",
            image: "c15-q8-NSP.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c"],
  },
];
