import app, { db } from "../config/firebase";
import moment from "moment";
import axios from "axios";

axios.defaults.baseURL =
  "https://europe-west1-projet-homy.cloudfunctions.net/api";

// Codes
export const codesLoc = [
  "LONESTN",
  "ORGGRP1",
  "ORGGRP2",
  "ORGGRP3",
  // "1234567",
  // "7654321",
  "hZ4632X",
  "327T7ja",
  "40bt1Z9",
  "0Fmw005",
  "g510N28",
  "2Fu1R80",
  "w26c90W",
  "T803C6w",
  "116aoG6",
  "hQ59j99",
  "456O3t6",
  "8A0z7k0",
  "i23r11Y",
  "w76WW88",
  "O3p835b",
  "68mE14g",
  "4iK91j9",
  "29k5F4Y",
  "6OJ8p64",
  "zFr9736",
  "T7i48n1",
  "Q6u491J",
  "Sk61c70",
  "wBN8659",
  "a5529Oz",
  "l6041NP",
  "S7B72c3",
  "6a98i2A",
  "E9h974y",
  "101OxL5",
  "1d799Yw",
  "2g8E5G3",
  "i1785Pp",
  "96d26Lv",
  "53rV1t4",
  "q77jU28",
  "090Gtq5",
  "l627DF7",
  "p16iC31",
  "Q6681Md",
  "51Rki83",
  "9N4vg23",
  "9x3TH14",
  "95N68hW",
  "Ls9324e",
  "9u44o6D",
  "2F5Fc20",
  "1Q1l298",
  "l7K75h1",
  "799J9cw",
  "KY7501x",
  "5j4j13N",
  "w86L9k8",
  "1c2G1j1",
  "5Q4Su11",
  "056NfO7",
  "i8O746d",
  "0F6rk47",
  "7Kt02X1",
  "889KP6b",
  "9Mp03E6",
  "I43E7m3",
  "02k21oY",
  "99mR324",
  "6B79J7x",
  "RVy6339",
  "589mD67",
  "S3F167b",
  "Vg6N692",
  "5279Qkr",
  "17y0eY7",
  "E34xs46",
  "49r65WM",
  "DPw1925",
  "rW75046",
  "36JrW14",
  "y1u95G1",
  "S7n6R19",
  "F036Y7g",
  "a68N75E",
  "9eq36C0",
  "ULb6922",
  "c40Jq20",
  "7u273U8",
  "7K56h9w",
  "37t34Lv",
  "2w7m1N8",
  "6s1Gu23",
  "G2491oY",
  "8Ww238g",
  "86k0wX0",
  "4568MoG",
  "W7q7Q44",
  "F7175Ry",
  "37v0S88",
  "3D72h9r",
  "47S5c2w",
  "2f0q55X",
  "76x6Ru9",
  "8B74B3i",
  "oT2435I",
  "A4e3h00",
  "309ZKp8",
  "0113qDb",
  "5rx956W",
  "93z9tU2",
  "T44T32f",
  "i3G3E33",
  "7d6aP94",
  "aD60244",
  "8Wm979j",
  "V6Or540",
  "u50038K",
  "1n6I3U8",
  "w973Qg9",
  "82X59ml",
  "7uRe552",
  "Y8r43a6",
  "54zLl50",
  "f86XT62",
  "3aI33w7",
  "uu4981K",
  "15dCo45",
  "9n62S6u",
  "0l1A49J",
  "p7f053C",
  "g33k8J5",
  "MjT5765",
  "17Sev59",
  "e43PI26",
  "123H3Dc",
  "v7H7I19",
  "35U8I4l",
  "784h5W0",
  "X552e2t",
  "cr4450X",
  "1x83p9E",
  "I999eW6",
  "3649d6J",
  "Q74H32h",
  "5T26f3i",
  "1Ze849h",
  "1pS90e5",
  "m3V8f11",
  "V2i97x1",
  "a5573xW",
  "6I851Vm",
  "P9S6j82",
  "9qU6K95",
  "0h8Q66E",
  "b4268yI",
  "6cO74b8",
  "wO423g5",
  "3N0u45R",
  "3aK62C0",
  "qF52906",
  "4sCj450",
  "yC340T1",
  "3Wf85B6",
  "0ca44Y6",
  "600x3WS",
  "3V76vU1",
  "5mN2a92",
  "3Qv7S13",
  "5qIj446",
  "5671lSf",
  "9E9x29u",
  "D6835zz",
  "52or28Q",
  "Y7Mq052",
  "d79149Y",
  "t5567xU",
  "J8s73a1",
  "4Y02m9H",
  "1Sw31z6",
  "9WX3z88",
  "lR31n72",
  "tE64o00",
  "1J0302b",
  "3Bq43G7",
  "734xH5H",
  "046tLG4",
  "2p49Yb4",
  "39x21E8",
  "4X4p4b7",
  "AP04f46",
  "30W73fx",
  "V9n203L",
  "0431Ssq",
  "83E7g2Z",
  "41LZ93v",
  "QJm3268",
  "Vc8065t",
  "252Chu9",
  "hkW6448",
  "54pX3S3",
  "f577J9V",
  "q0S776h",
  "Y7L538j",
  "8v4xU78",
  "51a6Wd5",
  "70x50FR",
  "3y41F3y",
  "842haX6",
  "Fe66v02",
  "cX072m1",
  "jL5C276",
  "6y6D2G0",
  "K33x9x3",
  "RS77g99",
  "E72Go84",
  "d50vI82",
  "2s85lK4",
  "6pO9l84",
  "P89aa58",
  "6b33ZN4",
  "74caB96",
  "48Wl683",
  "98N52iU",
  "94hK32e",
  "170Kd2q",
  "986i0fO",
  "As406F1",
  "A7R92j8",
  "J93Qd26",
  "Ap14r60",
  "49Z6q2Q",
  "19U3qk9",
  "o3Q29N5",
  "62G34ju",
  "2F21d6c",
  "gY1121w",
  "oM97n25",
  "7O8dm17",
  "7p9x0U3",
  "G02Ue21",
  "593kQY6",
  "342wLQ6",
  "e450fX0",
  "84Tsn96",
  "D26l6a5",
  "99Fv55n",
  "B27G66q",
  "41Ur94E",
  "Cl3B313",
  "3j12F4J",
  "36Go7u2",
  "Li74c34",
  "w508eA2",
  "7O37x40",
  "s9NV655",
  "812QX2f",
  "P1013wf",
  "7511S3h",
  "2A798aP",
  "875j61B",
  "25Si8o0",
  "6r1c4X5",
  "207Fv2c",
  "1RP40g4",
  "5Zz88O6",
  "2Wf1E98",
  "3m9Pd09",
  "7f95R1t",
  "15v2L2i",
  "5T61I4l",
  "5AW35d9",
  "v0037E8",
  "81hEx02",
  "1V9m13B",
  "47g05xH",
  "733j4Oq",
  "90P2bV7",
  "52D60cQ",
  "43gt06B",
  "u81Y6R9",
  "4Ad95S9",
  "6I4h7s1",
  "q3Q247A",
  "3j21Rm1",
  "5qL62O2",
  "611Rad4",
  "63M73Sm",
  "D14yx33",
  "1L310mc",
  "63G1Fm1",
  "NI53y15",
  "823xYj8",
  "3Gw59u8",
  "z39Hu93",
  "k4E9T85",
  "5Ij29w5",
  "0d2W764",
  "6rM535z",
  "80f3La2",
  "kGA3609",
  "40Nf6C6",
  "0365Dlz",
  "041yJ4o",
  "WS9a184",
  "3Tx419V",
  "C9c250L",
  "4793zRv",
  "3a1FU73",
  "1563GLa",
  "Q67f12x",
  "2u72PE2",
  "W38s1x4",
  "Z2pZ200",
  "f438vW1",
  "S4Zz687",
  "xL265E0",
  "493n8OF",
  "200euU8",
  "q3745Hc",
  "6j2p2E2",
  "o484VP5",
  "nB6325Y",
  "zT21I96",
  "m665j1N",
  "o890K7y",
  "5m4fG85",
  "38B8bw8",
  "X8626cd",
  "49vKx94",
  "4H0a6N6",
  "9W0rm26",
  "9He893K",
  "7112Gk8",
  "Y61V83a",
  "J8491Rh",
  "13S2y6Z",
  "lc95Z53",
  "124nTH7",
  "05s3On8",
  "66P65ie",
  "494pH6L",
  "747d1hF",
  "qP892w7",
  "9x0L35E",
  "3p63AP9",
  "44Hk4f7",
  "E45dK60",
  "1wNe083",
  "d3g195O",
  "A5Cw286",
  "Rv08O72",
  "I40x3O4",
  "h9r8O04",
  "0T8As95",
  "R18o38F",
  "RY5x680",
  "02F6D4m",
  "uZg9393",
  "19aS82t",
  "f03T2J5",
  "475Ob3K",
  "3573aKU",
  "5155Pym",
  "U090m9N",
  "8N519Sk",
  "dE2380l",
  "PW3t794",
  "L87l9l4",
  "E0Er384",
  "2a064QB",
  "9p922Is",
  "I661dt1",
  "Y36z6J9",
  "k963Ol8",
  "06zK04n",
  "Oh73z91",
  "8N127q7",
  "YH32v86",
  "4I9b976",
  "4q5M81H",
];

export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.regex) {
    isValid = rules.regex.test(value) && isValid;
  }

  if (rules.codes) {
    isValid = rules.codes.includes(value) && isValid;
  }

  return isValid;
};

// custom token login
export const customLogin = (refLoc, dispatch) => {
  // console.log("Axios function");
  axios
    .post("/login-epinay", { code: refLoc.value })
    .then((res) => {
      // console.log(res);
      app
        .auth()
        .signInWithCustomToken(res.data.token)
        .then((res) => {
          // console.log("Auth success: ", res.data);
          dispatch({ type: "SET_LOADING", loading: false });
        })
        .catch((err) => {
          console.log("auth error: ", err);
          dispatch({ type: "SET_LOADING", loading: false });
        });
    })
    .catch((err) => {
      console.log("axios error: ", err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Anon Login
export const anonLogin = (dispatch) => {
  app
    .auth()
    .signInAnonymously()
    .then((resp) => {
      console.log("Login successful");
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Add new locataire
export const addNewLoc = (refLoc, locataireID) => {
  const newLoc = {
    refLoc,
    locataireID,
    dateCreation: moment().format(),
  };
  if (refLoc !== "") {
    db.collection("locataires")
      .doc(refLoc)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  } else {
    db.collection("locataires")
      .doc(`anon-${locataireID}`)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  }
};
